import { SUPPORT_WIDGET_LOCAL_STORAGE_KEY } from "./constants";

export const computeInsetWithScrollbar = () => {
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.overflow = "scroll";
  outer.style.msOverflowStyle = "scrollbar";
  document.body.appendChild(outer);

  const inner = document.createElement("div");
  outer.appendChild(inner);
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  outer.parentNode.removeChild(outer);

  return scrollbarWidth + 24;
};

export const getLocalStorage = () => {
  try {
    return safeParseJSON(
      localStorage.getItem(SUPPORT_WIDGET_LOCAL_STORAGE_KEY),
    );
  } catch (error) {
    console.error("Failed to get item from localStorage:", error);
    return null;
  }
};

export const setLocalStorage = values => {
  try {
    localStorage.setItem(
      SUPPORT_WIDGET_LOCAL_STORAGE_KEY,
      JSON.stringify(values),
    );
  } catch (error) {
    console.error("Failed to set item in localStorage:", error);
  }
};

const safeParseJSON = key => {
  try {
    return JSON.parse(key);
  } catch (e) {
    console.error("Parsing JSON failed", e);
  }
};

// Code is copied and simplified from https://github.com/bryanwoods/autolink-js/blob/master/autolink.js
const URL_PATTERN =
  /(^|[\s\n]|<[A-Za-z]*\/?>)((?:https?|ftp):\/\/[-A-Z0-9+\u0026\u2019@#/%?=()~_|!:,.;]*[-A-Z0-9+\u0026@#/%=~()_|])/gi;

export const autolink = function (content) {
  try {
    return content.replace(URL_PATTERN, function (_match, space, url) {
      return `${space}<a class="break-all" href='${url}' target='_blank' rel='noopener noreferrer'>${url}</a>`;
    });
  } catch (error) {
    return content;
  }
};
