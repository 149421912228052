import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { analyticsTrackerApi } from "@/react/api/analyticsTrackerApi";
import { usePunditUserContext } from "@/react/contexts";

export const useRouteChangeEvent = () => {
  const { pathname, hash, search } = useLocation();
  const {
    masqueradingEntity,
    currentCommunity,
    currentCommunityMember,
    isLoading,
  } = usePunditUserContext();
  const fireRouteChangeEvent = useCallback(() => {
    const event = new CustomEvent("circle:route-change", {
      bubbles: true,
      cancelable: true,
      composed: false,
      detail: {
        path: pathname,
      },
    });

    document.dispatchEvent(event);
  }, [pathname]);

  const fireAnalyticsEvent = useCallback(() => {
    if (masqueradingEntity) {
      return;
    }
    if (isLoading) {
      return;
    }
    const properties = {
      community_id: currentCommunity?.id,
      community_member_id: currentCommunityMember?.id,
      path: pathname,
      hash: hash,
      search: search,
    };

    analyticsTrackerApi.trackPageView(properties);
  }, [
    hash,
    pathname,
    search,
    isLoading,
    currentCommunity,
    currentCommunityMember,
    masqueradingEntity,
  ]);

  useEffect(() => {
    fireRouteChangeEvent();
  }, [fireRouteChangeEvent]);

  useEffect(() => {
    fireAnalyticsEvent();
  }, [fireAnalyticsEvent]);
};
