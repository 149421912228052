import type { Params } from "../toQueryString";
import { toQueryString } from "../toQueryString";

const path = (path: string, params: Params = {}) =>
  `${path}?${toQueryString(params)}`;

export const segments = {
  index: ({ params = {} } = {}) =>
    path("/internal_api/community_segments", params),
};
