import { t } from "i18n-js";

export const CIRCLE_KB_URL = "https://help.circle.so";
export const CIRCLE_COMMUNITY_WHATS_NEW_URL =
  "https://community.circle.so/c/product-updates";
export const SEND_MESSAGE_FORM_DEFAULT_VALUES = {
  text: "",
  type: "",
  area: "",
  is_important: false,
};
export const SUPPORT_WIDGET_LOCAL_STORAGE_KEY = "support-widget-state";
export const WIDGET_HIDDEN_ROUTES = [
  "/messages/new",
  "/messages",
  "/live",
  "/features/visual-refresh",
];

export const SUPPORT_WIDGET_DEFAULT_TRANSITIONS = {
  enter: "transition ease-out duration-200 transform",
  enterFrom: "opacity-0 scale-95",
  enterTo: "opacity-100 scale-100",
  leave: "transition ease-in duration-200 transform",
  leaveFrom: "opacity-100 scale-100",
  leaveTo: "opacity-0 scale-95",
};

export const MAX_TUTORIAL_POSTS = 3;

export const ATTACHMENT_STATUS_COMPLETED = "completed";
export const ATTACHMENT_STATUS_FAILED = "failed";
export const ATTACHMENT_STATUS_UPLOADED = "uploaded";
export const ATTACHMENT_STATUS_UPLOADING = "uploading";

export const FIVE_MINUTES = 5 * 60 * 1000;
export const FIFTEEN_MINUTES = 15 * 60 * 1000;
export const ONE_HOUR = 60 * 60 * 1000;
export const ONE_YEAR = 365 * 24 * 60 * 60 * 1000;

export const TICKET_TYPES = [
  { text: t("support_widget.constants.bug"), value: "bug" },
  { text: t("support_widget.constants.feedback"), value: "feedback" },
  { text: t("support_widget.constants.question"), value: "question" },
];

export const PRODUCT_AREAS = [
  { text: t("support_widget.constants.admin_billing"), value: "admin_billing" },
  { text: t("support_widget.constants.apps"), value: "apps" },
  { text: t("support_widget.constants.courses"), value: "courses" },
  { text: t("support_widget.constants.events"), value: "events" },
  {
    text: t("support_widget.constants.live_streams_and_rooms"),
    value: "live_streams_and_rooms",
  },
  {
    text: t("support_widget.constants.member_access"),
    value: "member_access",
  },
  {
    text: t("support_widget.constants.paywalls"),
    value: "paywalls",
  },
  {
    text: t("support_widget.constants.posts_and_comments"),
    value: "posts_and_comments",
  },
  { text: t("support_widget.constants.settings"), value: "settings" },
  { text: t("support_widget.constants.other"), value: "other" },
];

export const SPECIAL_WORDS = [
  "cx",
  "human",
  "humans",
  "representative",
  "representatives",
  "support",
  "customer",
  "customers",
  "service",
  "services",
];

export const GREETING_WORDS = [
  "hello",
  "hi",
  "hey",
  "howdy",
  "greetings",
  "good",
  "thank",
  "thanks",
  "bye",
  "goodbye",
  "thank you",
  "great",
  "nice",
  "cool",
  "awesome",
];
