import { absolutePath } from "./absolutePath";

export const gamification = {
  settings: ({ params = {} } = {}) =>
    absolutePath("/gamification/settings", params),
  members: ({ params = {} } = {}) =>
    absolutePath("/gamification/members", params),
  leaderboard: ({ params = {} } = {}) =>
    absolutePath("/gamification/leaderboard", params),
};
